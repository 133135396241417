import React from 'react';
import './ContactsPage.css'

export class ContactsPage extends React.Component {
    render() {
        return (
            <div id="contacts-page-div">

                <div id="general-email-div">
                    <h1>Please send us any questions you may have:</h1>
                    <div id='general-email-button' onClick={()=>{window.location.href="mailto:rpcrichva@gmail.com"}}>
                        <h4>rpcrichva@gmail.com</h4>
                    </div>
                </div>
    
                <div id='cielo-div'>
                    <div className='cielo-inner-div' id='cielo-picture-div'>

                    </div>
                    <div className='cielo-inner-div' id='cielo-info-div'>
                        <h1>Dr. Maria Cielo Sinsioco</h1>
                        <h5>Founder/Director of Richmond Pilipino Class</h5>

                        <div id='decorative-line'></div>
                    </div>
                </div>

                <div id='other-contacts-div'>

                    <div className='contacts-div'>
                        <div className='contacts-div-left' id="igharas-picture-div">
                        </div>
                        <div className='contacts-div-right'>
                            <h2>Rosario Igharas</h2>
                            <h5>Language Coordinator</h5>

                        </div>
                    </div>

                    

                    <div className='contacts-div'>
                        <div className='contacts-div-left' id="colina-picture-div">
                        </div>
                        <div className='contacts-div-right'>
                            <h2>Tiberius Colina</h2>
                            <h5>RPC Website Developer</h5>

                        </div>
                    </div>

                </div>

                <h2 id="rpc-volunteers-title">RPC Volunteers (2022-2023)</h2>
                <div id='other-volunteers-div'>
                    <div className='other-volunteers-subdiv'>
                        <h3>RPC Director</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Maria Cielo Sinsioco</h4>
                    </div>
                    <div className='other-volunteers-subdiv'>
                        <h3>Language Coordinators</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Rosario Igharas (Adult)</h4>
                        <h4>Joan Tique (Children/Youth)</h4>
                    </div>

                    <div className='other-volunteers-subdiv'>
                        <h3>Music Coordinators</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Reginald Oandasan</h4>
                        <h4>Geraldine Seibert</h4>
                        <h4>Wilma Valderrama</h4>
                    </div>

                    <div className='other-volunteers-subdiv'>
                        <h3>Dance Coordinators</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Jennifer Colina</h4>
                        <h4>Ludwig Osorio</h4>
                        <h4>Myra Hudencial</h4>
                    </div>

                    <div className='other-volunteers-subdiv'>
                        <h3>Costumes Coordinator</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Myrlina Hunley</h4>
                    </div>

                    <div className='other-volunteers-subdiv'>
                        <h3>Children's Arts</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Emily Wedock</h4>
                    </div>

                    <div className='other-volunteers-subdiv'>
                        <h3>Psychotherapy</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Kristin Lennox</h4>
                        <h4>Natalie Realubit</h4>
                    </div>

                    <div className='other-volunteers-subdiv'>
                        <h3>Philippine Cuisine</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Arsenia Suarez</h4>
                        <h4>Sonia Martinez</h4>
                    </div>

                    <div className='other-volunteers-subdiv'>
                        <h3>Sounds/Technology</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Reggie Oandasan</h4>
                        <h4>Alvin Ho</h4>
                        <h4>Russell Valdez</h4>
                        <h4>Raphy Tantoan</h4>
                        <h4>Donna Madocks</h4>
                    </div>

                    <div className='other-volunteers-subdiv'>
                        <h3>Website</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Tiberius Colina</h4>
                        <h4>Fidel Rodriguez</h4>
                    </div>

                    <div className='other-volunteers-subdiv'>
                        <h3>Photography</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Erwin Daniel</h4>
                        <h4>Jennifer Colina</h4>
                        <h4>Ferdi Hudencial</h4>
                        <h4>Rowley Molina</h4>
                    </div>

                    <div className='other-volunteers-subdiv'>
                        <h3>Supplies</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Connie Amargo</h4>
                        <h4>Alice Daniel</h4>
                        <h4>Milyn Magtang</h4>
                    </div>

                    <div className='other-volunteers-subdiv'>
                        <h3>Spiritual Adviser</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Fr. Renier Supranes</h4>
                    </div>

                    <div id="volunteer-teachers-div">
                    
                        <h3>Volunteer Teachers/Support</h3>
                        <div className='other-volunteers-decorative-line'></div>
                        <h4>Jayson & Bernadette Abuan</h4>
                        <h4>Jaydy Ann Amargo</h4>
                        <h4>Nidjan Aquino</h4>
                        <h4>Jeo Basilan</h4>
                        <h4>Alex & Lorna Battles</h4>
                        <h4>Rob & Jenn Bies</h4>
                        <h4>Nards & Lisa Bonjoc</h4>
                        <h4>Jenny Bost</h4>
                        <h4>Recie Brooke</h4>
                        <h4>Joy Crabtree</h4>
                        <h4>Melane De los Santos</h4>
                        <h4>Conchita Ebarle</h4>
                        <h4>Carol Hackley</h4>
                        <h4>Anne Ho</h4>
                        <h4>Rizza Howard</h4>
                        <h4>Rowen Igharas</h4>
                        <h4>Rachel Korpal</h4>
                        <h4>Armand & Lily Liangco</h4>
                        <h4>Teri Mercado</h4>
                        <h4>Bobot Molina</h4>
                        <h4>Emer & Genevieve Matulin</h4>
                        <h4>Agnes Oandasan</h4>
                        <h4>Dr. Elizabeth Panaguiton</h4>
                        <h4>Larry & Connie Prestosa</h4>
                        <h4>Nor and Barbie Purificacion</h4>
                        <h4>Imelda Raguindin</h4>
                        <h4>Norma Relucio</h4>
                        <h4>Vivina Rodriguez</h4>
                        <h4>Merle Sarmiento</h4>
                        <h4>Raymond White</h4>
                        <h4>Peggy Zwicker</h4>
                        <h4>North Central Virginia Association</h4>
                        <h4>of Philippine Physicians (NCVAPP)</h4>
                        <h4>Dr. Nonoy Zuniga</h4>
                        <h4>Mymy Magtang</h4>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                    </div>
                </div>

                <h2 id="rpc-volunteers-title">RPC Volunteers (2019-2020)</h2>

                <div id='other-volunteers-div'>
                    <div className='other-volunteers-subdiv'>
                        <h3>Music</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Larry Prestosa</h4>
                        <h4>Reginald Oandasan</h4>
                        <h4>Joey Colina</h4>
                        <h4>Chris Ayers</h4>
                    </div>
                    <div className='other-volunteers-subdiv'>
                        <h3>Dance</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Ludwig Osorio</h4>
                        <h4>Jennifer Colina</h4>
                        <h4>Yvette Coronado-Mercer</h4>
                        <h4>Regina Anderson</h4>
                        <h4>Rowena Colina</h4>
                        <h4>Myra Hudencial</h4>
                        <h4>Roy Anderson</h4>
                        <h4>Tina Bonjoc</h4>
                    </div>
                    <div className='other-volunteers-subdiv'>
                        <h3>Psychotherapy</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Yvette Coronado-Mercer</h4>
                        <h4>Kristin Lennox</h4>
                    </div>
                    <div className='other-volunteers-subdiv'>
                        <h3>Martial Arts</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Aris Taloma</h4>
                        <h4>Ernie Mina</h4>
                    </div>
                    <div className='other-volunteers-subdiv'>
                        <h3>Sounds and Technology</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Ernie Mina</h4>
                        <h4>Alvin Ho</h4>
                        <h4>Fidel Rodriguez</h4>
                        <h4>Donna Madocks</h4>
                    </div>
                    <div className='other-volunteers-subdiv'>
                        <h3>Philippine Cuisine</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Arsenia Suarez</h4>
                        <h4>Vivina Rodriguez</h4>
                        <h4>Anne Ho</h4>
                        <h4>Pacita Almazan</h4>
                        <h4>Conchita Ebarle</h4>
                    </div>
                    <div className='other-volunteers-subdiv'>
                        <h3>Childrens' Arts</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Emily Wedlock</h4>
                        <h4>Resie Lee</h4>
                    </div>
                    <div className='other-volunteers-subdiv'>
                        <h3>Stage Decoration Props/Preparation</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Joy Crabtree</h4>
                        <h4>Jason Abuan</h4>
                        <h4>Raymond White</h4>
                    </div>
                    <div className='other-volunteers-subdiv'>
                        <h3>Costumes</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Myrlina Hunley</h4>
                    </div>

                    <div className='other-volunteers-subdiv'>
                        <h3>Photography</h3>
                        <div className='other-volunteers-decorative-line'></div>

                        <h4>Sue Berinato</h4>
                        <h4>Oscar Sibal</h4>
                        <h4>Jenny Colina</h4>
                        <h4>Ferdi Hudencial</h4>
                    </div>
                </div>

                <div id="volunteer-teachers-div">
                    
                    <h3>Volunteer Teachers/Support</h3>
                    <div className='other-volunteers-decorative-line'></div>
                    <h4>Bernadette Abuan</h4>
                    <h4>Connie Amargo</h4>
                    <h4>Drew & Greta Armada</h4>
                    <h4>Jessica  Barbour</h4>
                    <h4>Rob & Jenn Bies</h4>
                    <h4>Ed Budi (R.I.P.)</h4>
                    <h4>Paul & Nilda Boggs</h4>
                    <h4>Sue Berinato</h4>
                    <h4>Leonard & Lisa Bonjoc</h4>
                    <h4>Jen Bost</h4>
                    <h4>Kevin Butay</h4>
                    <h4>Elizabeth Carlton- Dowdy</h4>
                    <h4>Susan Carlton</h4>
                    <h4>Mike & Lhyn Carson</h4>
                    <h4>Sotera Catayas (R.I.P.)</h4>
                    <h4>Rossee Custalow</h4>
                    <h4>Erwin & Alice Daniel</h4>
                    <h4>Rosario Darby</h4>
                    <h4>Jinky Davis</h4>
                    <h4>Melane de los Santos</h4>
                    <h4>David Emmerling</h4>
                    <h4>Mila Fat</h4>
                    <h4>Maricor Fisher</h4>
                    <h4>Dr. Conrad Gonzalez</h4>
                    <h4>Romy Hernandez</h4>
                    <h4>Rowen Igharas</h4>
                    <h4>Sheila Kenney</h4>
                    <h4>Recie Lee</h4>
                    <h4>Estelita Legazpi</h4>
                    <h4>Armand Liangco</h4>
                    <h4>Bebot & Emily Macuno</h4>
                    <h4>Rommel & Beng Magtoto</h4>
                    <h4>Romy & Teri Mercado</h4>
                    <h4>Servillano Molina</h4>
                    <h4>Mike & Zennifer Moore</h4>
                    <h4>Agnes Oandasan</h4>
                    <h4>Dr. Elizabeth Panaguiton</h4>
                    <h4>Ian & Carmina Parong</h4>
                    <h4>Vith Pollard</h4>
                    <h4>James & Myra Powell</h4>
                    <h4>Nor & Barbie Purificacion</h4>
                    <h4>Connie Prestosa</h4>
                    <h4>Beni Robertson</h4>
                    <h4>Pam Robertson</h4>
                    <h4>Merle Sarmiento</h4>
                    <h4>Geraldine Siebert</h4>
                    <h4>Renalyn Tinga</h4>
                    <h4>Kenji Uchida- Ares</h4>
                    <h4>Jenny Vanhook</h4>
                    <h4>Jay & Belle Villanueva</h4>
                    <h4>Peggy Zwicker</h4>
                    <h4>North Central Virginia Association</h4>
                    <h4>of Philippine Physicians (NCVAPP)</h4>
                    <br></br>
                </div>

                

                
            </div>
        )
    }
}