import React from 'react';
import './EventsPage.css'

import { EventsPageEvent } from '../EventsPageEvent/EventsPageEvent'

export class EventsPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            events: []
        }
    }

    // This part of the code will return when Postgres is moved to EC2 instance
    async getAllEvents() {
        await fetch('https://bndy9g3yhb.execute-api.us-east-1.amazonaws.com/events')
            .then(response => response.json())
            .then(data =>{
                this.setState({ events: data })
            });

        console.log(this.state.events);
    }

    componentDidMount() {
        this.getAllEvents()
    }

    render() {
        return (
            <section id="events-page-section">
                {this.state.events.map(function(d, idx) {
                    {console.log(d)}
                    return <EventsPageEvent
                        key={idx}
                        name={d['event-name']['S']}
                        date={d['event-time']['S']}
                        location={d.event_loc}
                        description={d['event-desc']['S']}
                    ></EventsPageEvent>
                })}
            </section>
        )
    }
}