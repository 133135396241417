import React from 'react';
import './VideosPage.css'

export class VideosPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            videos: []
        }
    }

    componentDidMount() {
        this.getAllVideos()
    }

    async getAllVideos() {
        await fetch('https://bndy9g3yhb.execute-api.us-east-1.amazonaws.com/videos')
            .then(response => response.json())
            .then(data => {
                // Sort the data
                data.sort((a, b) => {
                    // Convert dates to Date objects for comparison
                    let dateA = a['video-date'] ? new Date(a['video-date']) : null;
                    let dateB = b['video-date'] ? new Date(b['video-date']) : null;
    
                    // Handle null dates
                    if (dateA === null && dateB === null) return 0; // Both dates are null
                    if (dateA === null) return 1; // Null dates should come after non-null dates
                    if (dateB === null) return -1; // Non-null date should come before null date
    
                    // Compare dates
                    return dateA - dateB;
                });
    
                // Set state with sorted data
                this.setState({ videos: data });
            });
    }

    formatDate(inputDate) {
        // Split the input string by "-" and " " to get individual date components
        var parts = inputDate.split(/[-\s:]/);
        
        // Extract year, month, and day
        var year = parts[0];
        var month = parts[1];
        var day = parts[2];
    
        // Format the date as mm/dd/yyyy
        var formattedDate = month + '/' + day + '/' + year;
    
        return formattedDate;
    }

    getVideoDivs(division) {
        let videosList = this.state.videos.filter(video => video['video-division'] === division);

        return videosList.map((video, i) => (
            <a href={videosList[i]['video-link']} target='_blank'>
                <div className='video-div'>
                    <p>{videosList[i]['video-division']}</p>
                    <h6 className="video-div-date">{videosList[i]['video-date'] !== null ? this.formatDate(videosList[i]['video-date']) : null}</h6>
                    <h1 style={{position:'relative'}}>{videosList[i]['video-name']}</h1>
                </div>
            </a>
          ))
    }
 
    render() {
        return (
            <html>
                <div id='videos-div-main'>
                <div className='videos-subdiv' id='rpcpa-videos-subdiv'>

                        {this.getVideoDivs("Richmond Pilipino Class")}
                    </div>
                    <div className='videos-subdiv' id='rpcpa-videos-subdiv' style={{backgroundColor:'rgb(245,245,245)'}}>

                        
                        {this.getVideoDivs("RPC Performing Arts")}
                    </div>
                    <div className='videos-subdiv' id='dulaang-videos-subdiv'>
                        {this.getVideoDivs("Dulaang Pilipino")}
                    </div>

                    <h1>Videos of RPC Sessions</h1>
                    <div className='decorative-div'></div>

                    <iframe src="https://drive.google.com/file/d/1IglYZzv1zmzQG1T8Bum6YvY8L5035mk3/preview" width="300" height="200" allow="autoplay"></iframe>
                    <iframe src="https://drive.google.com/file/d/1JBE8WPVpp3l0RsPlqmgfFDuTcuwHtzM9/preview" width="300" height="200" allow="autoplay"></iframe>
                    <iframe src="https://drive.google.com/file/d/1kaLk-kc9XoBVBcRtkLtPsMONBatc43O8/preview" width="300" height="200" allow="autoplay"></iframe>
                    <iframe src="https://drive.google.com/file/d/1mHar-duBx69cmFLtMC-E1vnbCndKybWv/preview" width="300" height="200" allow="autoplay"></iframe>
                    <iframe src="https://drive.google.com/file/d/1UjVbDKxQQVi-UO9ip72hM3f5vBgjdYNT/preview" width="640" height="480" allow="autoplay"></iframe>
                    <iframe src="https://drive.google.com/file/d/197vGyr1nUMLdEtYBk0IsNvlB-Mx8Vxj2/preview" width="640" height="480" allow="autoplay"></iframe>
                </div>

                
            </html>
        )
    }
}

