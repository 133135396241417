import './EventsPageEvent.css'
import HTMLReactParser from 'html-react-parser';

// When there is a line break, the database returns it as \r\n
// So, convert all of them to <br/>
function readyString(string) {
    const result = string.split('\r\n').join('<br>');
    return result
}

export function EventsPageEvent(props) {

    let name = props.name;
    let time = props.date;
    let description = props.description;

    return (
        <div class='events-page-event'>
            <p class='events-page-event-date-loc'>{time}</p>
            <h1>{name}</h1>
            <h4>{description}</h4>
        </div>
    )
}