import React from 'react';
import './StartingPage.css'

export class StartingPage extends React.Component {

    render() {
        return (
            <div id="starting-page-div">
                <h1>Thank you to our sponsors!</h1>
                <h4>This website is made possible by funding from the AASOCV and the Verizon Foundation, with support from FAACV.</h4>

                <div id='logo-section'>

                    <div className='logo-box' id='verizon-logo-box'>
                        <div className='logo-box-top'>
                            <div className="starting-page-logo" id="verizon-logo"></div>
                        </div>
                        <div className='logo-box-bottom'>
                            <p>Sponsor</p>
                            <h3>Verizon Foundation</h3>
                        </div>
                    </div>

                    <div className='logo-box' id='other-logo-box'>
                        <div className='logo-box-top'>
                            <div className="starting-page-logo" id="other-logo"></div>
                        </div>
                        <div className='logo-box-bottom'>
                            <p>Sponsor</p>
                            <h3>AASOCV</h3>
                        </div>
                        
                    </div>
                    <div className='logo-box' id='faacv-logo-box'>
                        <div className='logo-box-top'>
                            <div className="starting-page-logo" id="faacv-logo"></div>
                        </div>
                        <div className='logo-box-bottom'>
                            <p>Sponsor</p>
                            <h3>FAACV</h3>
                        </div>
                    </div>

                </div>

                <div id='about-us-section'>
                    <div className='about-us-half' id='about-us-left'>
                        <h1>About Us</h1>
                        <p>We are a Richmond-based Filipino-American organization aimed at teaching the community about the culture and language of the Philippines. Students of Richmond Pilipino Class learn about the Philippines in a supportive, welcoming environment.</p>
                    </div>
                    <div className='about-us-half' id='about-us-right'>
                        <div id="rpc-class-image"></div>
                    </div>
                </div>

                <div id='about-us-section'>
                    <div className='about-us-half' id='about-us-left'>
                        <h1>Volunteers</h1>
                        <p>Since the organization’s beginning, RPC Volunteers have been dedicated to upholding a warm and welcoming learning environment. Thank you to our dedicated volunteers throughout the years!</p>
                    </div>
                    <div className='about-us-half' id='about-us-right'>
                        <iframe src="https://drive.google.com/file/d/1E-hU6Ez6rOAACh9PN6RIGepHDJ_WZhLL/preview" width="300" height="200" allow="autoplay"></iframe>
                    </div>
                </div>

                {/* <h1 id ="about-us-h1">About Us</h1>
                <h4>We are a Richmond-based Filipino-American organization aimed at teaching the community about the culture and language of the Philippines. 
                    Students of Richmond Pilipino Class learn about the Philippines in a supportive, welcoming environment.</h4>

                <div id="rpc-class-image"></div>
                <iframe src="https://drive.google.com/file/d/1E-hU6Ez6rOAACh9PN6RIGepHDJ_WZhLL/preview" width="800" height="480" allow="autoplay"></iframe>
                
                <br></br>

                <h1>Thank you to our dedicated volunteers through the years!</h1>

                <br></br>
                <br></br> */}
            </div>
        )
    }
}