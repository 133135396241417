import './App.css';
import React from 'react';
 
import { StartingPage } from "./Components/StartingPage/StartingPage"
import { PhotosPage } from "./Components/PhotosPage/PhotosPage"
import { VideosPage } from "./Components/VideosPage/VideosPage"
import { FeaturesPage } from "./Components/FeaturesPage/FeaturesPage"
import { ContactsPage } from "./Components/ContactsPage/ContactsPage"
import { EventsPage } from "./Components/EventsPage/EventsPage"
import { GlobalHeader } from './Components/GlobalHeader/GlobalHeader'

class App extends React.Component {

  constructor(props) {
    super(props)
    this.saveVisitDate();

    this.state = {
      currentPage: "Starting Page"
    }
  }

  switchToMain() {
    this.setState({ currentPage:"Starting Page" })
  }

  switchToPhotos() {
    this.setState({ currentPage:"Photos Page" })
  }

  switchToVideos() {
    this.setState({ currentPage:"Videos Page" })
  }

  switchToEvents() {
    this.setState({ currentPage:"Events Page" })
  }

  switchToVolunteers() {
    this.setState({ currentPage:"Contacts Page" })
  }

  switchToFeatures() {
    this.setState({ currentPage:"Features Page" })
  }

  async saveVisitDate() {
    // Calls lambda function that records visit date for statistics
    const response = await fetch("https://bndy9g3yhb.execute-api.us-east-1.amazonaws.com/visit", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
            },
            body: JSON.stringify({

            }),
            });

            if (response.ok) {
              console.log("Saved visit date")
          } else {
            console.log("Could not save visit date")
              // Handle error
          }
    }

  render() {
    if (this.state.currentPage === "Starting Page") {
      return (
        <div>
          <div className="top-header-div">

            <div className='rpc-logo-div' onClick={()=>{this.switchToMain()}}></div>

            <div className="top-header-button-container">
              <div className="top-header-button" id="top-header-photos-button" onClick={()=>{this.switchToPhotos()}}>
                <h3>Photos</h3>
              </div>
              <div className="top-header-button" id="top-header-videos-button" onClick={()=>{this.switchToVideos()}}>
                <h3>Videos</h3>
              </div>
              <div className="top-header-button" id="top-header-videos-button" onClick={()=>{this.switchToFeatures()}}>
                <h3>Features</h3>
              </div>
              <div className="top-header-button" id="top-header-events-button" onClick={()=>{this.switchToEvents()}}>
                <h3>Events</h3>
              </div>
              <div className="top-header-button" id="top-header-volunteers-button" onClick={()=>{this.switchToVolunteers()}}>
                <h3>Volunteers</h3>
              </div>
            </div>
          </div>
          <GlobalHeader bigText="Richmond Pilipino Class" smallText="Philippine cultural enrichment program in central Virginia  Including Dulaang Pilipino (Philippine Theater) and Richmond Pilipino Class Performing Arts."/>
          <StartingPage/>
        </div>
      )
    } else if (this.state.currentPage === "Photos Page") {
      return (
        <div>
          <div className="top-header-div">

            <div className='rpc-logo-div' onClick={()=>{this.switchToMain()}}></div>

            <div className="top-header-button-container">
              <div className="top-header-button" id="top-header-photos-button" onClick={()=>{this.switchToPhotos()}}>
                <h3>Photos</h3>
              </div>
              <div className="top-header-button" id="top-header-videos-button" onClick={()=>{this.switchToVideos()}}>
                <h3>Videos</h3>
              </div>
              <div className="top-header-button" id="top-header-videos-button" onClick={()=>{this.switchToFeatures()}}>
                <h3>Features</h3>
              </div>
              <div className="top-header-button" id="top-header-events-button" onClick={()=>{this.switchToEvents()}}>
                <h3>Events</h3>
              </div>
              <div className="top-header-button" id="top-header-volunteers-button" onClick={()=>{this.switchToVolunteers()}}>
                <h3>Volunteers</h3>
              </div>
            </div>
          </div>
          <GlobalHeader bigText="Photos" smallText="Richmond Pilipino Class"/>
          <PhotosPage/>
        </div>
      )
    } else if (this.state.currentPage === "Videos Page") {
      return (
        <div>
          <div className="top-header-div">

            <div className='rpc-logo-div' onClick={()=>{this.switchToMain()}}></div>

            <div className="top-header-button-container">
              <div className="top-header-button" id="top-header-photos-button" onClick={()=>{this.switchToPhotos()}}>
                <h3>Photos</h3>
              </div>
              <div className="top-header-button" id="top-header-videos-button" onClick={()=>{this.switchToVideos()}}>
                <h3>Videos</h3>
              </div>
              <div className="top-header-button" id="top-header-videos-button" onClick={()=>{this.switchToFeatures()}}>
                <h3>Features</h3>
              </div>
              <div className="top-header-button" id="top-header-events-button" onClick={()=>{this.switchToEvents()}}>
                <h3>Events</h3>
              </div>
              <div className="top-header-button" id="top-header-volunteers-button" onClick={()=>{this.switchToVolunteers()}}>
                <h3>Volunteers</h3>
              </div>
            </div>
          </div>
          <GlobalHeader bigText="Videos" smallText="Richmond Pilipino Class"/>
          <VideosPage/>
        </div>
      )
    } else if (this.state.currentPage === "Features Page") {
      return (
        <div>
          <div className="top-header-div">

            <div className='rpc-logo-div' onClick={()=>{this.switchToMain()}}></div>

            <div className="top-header-button-container">
              <div className="top-header-button" id="top-header-photos-button" onClick={()=>{this.switchToPhotos()}}>
                <h3>Photos</h3>
              </div>
              <div className="top-header-button" id="top-header-videos-button" onClick={()=>{this.switchToVideos()}}>
                <h3>Videos</h3>
              </div>
              <div className="top-header-button" id="top-header-videos-button" onClick={()=>{this.switchToFeatures()}}>
                <h3>Features</h3>
              </div>
              <div className="top-header-button" id="top-header-events-button" onClick={()=>{this.switchToEvents()}}>
                <h3>Events</h3>
              </div>
              <div className="top-header-button" id="top-header-volunteers-button" onClick={()=>{this.switchToVolunteers()}}>
                <h3>Volunteers</h3>
              </div>
            </div>
          </div>
          <GlobalHeader bigText="Features" smallText="Richmond Pilipino Class"/>
          <FeaturesPage/>
        </div>
      )
    }  
    else if (this.state.currentPage === "Contacts Page") {
      return (
        <div>
          <div className="top-header-div">

            <div className='rpc-logo-div' onClick={()=>{this.switchToMain()}}></div>

            <div className="top-header-button-container">
              <div className="top-header-button" id="top-header-photos-button" onClick={()=>{this.switchToPhotos()}}>
                <h3>Photos</h3>
              </div>
              <div className="top-header-button" id="top-header-videos-button" onClick={()=>{this.switchToVideos()}}>
                <h3>Videos</h3>
              </div>
              <div className="top-header-button" id="top-header-videos-button" onClick={()=>{this.switchToFeatures()}}>
                <h3>Features</h3>
              </div>
              <div className="top-header-button" id="top-header-events-button" onClick={()=>{this.switchToEvents()}}>
                <h3>Events</h3>
              </div>
              <div className="top-header-button" id="top-header-volunteers-button" onClick={()=>{this.switchToVolunteers()}}>
                <h3>Volunteers</h3>
              </div>
            </div>
          </div>
          <GlobalHeader bigText="Contacts" smallText="Richmond Pilipino Class"/>
          <ContactsPage/>
        </div>
      )
    } else if (this.state.currentPage === "Events Page") {
      return (
        <div>
          <div className="top-header-div">

            <div className='rpc-logo-div' onClick={()=>{this.switchToMain()}}></div>

            <div className="top-header-button-container">
              <div className="top-header-button" id="top-header-photos-button" onClick={()=>{this.switchToPhotos()}}>
                <h3>Photos</h3>
              </div>
              <div className="top-header-button" id="top-header-videos-button" onClick={()=>{this.switchToVideos()}}>
                <h3>Videos</h3>
              </div>
              <div className="top-header-button" id="top-header-videos-button" onClick={()=>{this.switchToFeatures()}}>
                <h3>Features</h3>
              </div>
              <div className="top-header-button" id="top-header-events-button" onClick={()=>{this.switchToEvents()}}>
                <h3>Events</h3>
              </div>
              <div className="top-header-button" id="top-header-volunteers-button" onClick={()=>{this.switchToVolunteers()}}>
                <h3>Volunteers</h3>
              </div>
            </div>
          </div>
          <GlobalHeader bigText="Events" smallText="Richmond Pilipino Class"/>
          <EventsPage/>
        </div>
      )
    }
  }
}

export default App;
