import React from 'react';
import './GlobalHeader.css'

export class GlobalHeader extends React.Component {
    render() {
        return (
            <div id='global-header-div'>
                <div id="global-header-text-div">
                    <h1 id="main-big-text">{this.props.bigText}</h1>
                    <p id="small-below-text">{this.props.smallText}</p>
                </div>
            </div>
        )
    }
}